<template>
	<div>
		<b-button class="social-login-google" @click="signInWithGoogle">
			<b-img
				:src="require('@/assets/images/icons/social/google.png')"
				alt="Google Logo"
			/>
			<div>Continue with Google</div>
		</b-button>

		<b-button v-if="false" class="social-login-facebook" @click="signInWithFacebook">
			<b-img
				:src="require('@/assets/images/icons/social/facebook.png')"
				alt="Facebook Logo"
			/>
			<div>Continue with Facebook</div>
		</b-button>
	</div>
</template>

<script>
import { BImg, BButton } from 'bootstrap-vue'
import { getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider, FacebookAuthProvider } from "firebase/auth";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
	components: {
		BButton,
		BImg
	},
	methods: {
		signInWithGoogle() {
			signInWithPopup(getAuth(), new GoogleAuthProvider())
				.then(this.signInSuccess)
				.catch(this.signInError)
		},
		signInWithApple() {
			signInWithPopup(getAuth(), new OAuthProvider('apple.com'))
				.then(this.signInSuccess)
				.catch(this.signInError);
		},
		signInWithFacebook() {
			signInWithPopup(getAuth(), new FacebookAuthProvider())
				.then(this.signInSuccess)
				.catch(this.signInError);
		},

		signInSuccess(credentials) {
			if (!credentials.user.emailVerified) {
				throw { code: 'auth/unverified-email' }
			}

			this.$store.dispatch("auth/fetchUser", credentials.user);
			localStorage.setItem('user', JSON.stringify(credentials.user));
			this.$toast({
				component: ToastificationContent,
				props: {
					title: 'You successfully signed in!',
					icon: 'EditIcon',
					variant: 'success',
				},
			});
			this.$router.replace({ name: 'home' });
		},
		signInError(error) {
			console.error(error);

			const errorMessages = {
				'auth/unverified-email': "Email is not verified.",
				'auth/wrong-password': "Wrong password!"
			}

			this.$toast({
				component: ToastificationContent,
				props: {
					title: error.code in errorMessages ? errorMessages[error.code] : 'Oops, something went wrong!',
					icon: 'EditIcon',
					variant: 'danger',
				},
			});
		}
	}
}
</script>

<style lang="scss" scoped>
button[class*="social-login-"] {
	width: 100%;
	border: none;
	border-radius: 4px;
	display: inline-block;
	line-height: 20px;
	padding: 12px 18px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	border: 1px solid black;

	> div {
		text-align: center;
		width: 100%;
		margin-top: 2px;
		font-size: 14px;
		display: block;
	}

	> img {
		height: 22px;
		width: 22px;
	}
}
.social-login-google {
	background-color: white !important;
	color: black !important;

	&:active, &:focus {
		background-color: unset !important;
	}
}
.social-login-apple {
	background-color: black !important;
}
.social-login-facebook {
	background-color: #4577F1 !important;

	&:active, &:focus {
		background-color: #4577F1 !important;
	}
}
</style>
